import keyBy from 'lodash/keyBy';

export const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Spanish', value: 'es' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Russian', value: 'ru' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Creole', value: 'cr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Slovakian', value: 'sk' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Kazakh', value: 'kz' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Greek', value: 'el' },
  { label: 'Swedish', value: 'se' },
  { label: 'Italian', value: 'it' },
  { label: 'Romanian', value: 'ro' }
];

const LANGUAGES_MAP = keyBy(LANGUAGES, 'value');

export function getHumanLocale(locale: string): string {
  return LANGUAGES_MAP[locale]?.label || locale;
}

export const getLangLabel = (value: string): string | null => {
  if (!value) {
    return '-';
  }

  return LANGUAGES.find((v) => v.value === value)?.label || '-';
};
